@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
    font-style: italic;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}
