/* .customContent {
    font-family: 'OpenSans';
} */

.bullets ul {
    list-style-type: none;
    padding: 0;
}

.bullets ul li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 32px;
    line-height: 18px;
}
.bullets ul li:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    text-align: center;
    color: #d3d3d3;
    /* border: 2px solid #004945; */
    /* border-radius: 50%; */
    counter-increment: tubemap;
    content: url('./../../../../assets/asset-tick.svg');
}

a {
    color: var(--mui-palette-primary-contrastText);
}
